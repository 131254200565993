<template>
	<div>
		<top-bar
			:phone="defaults[locale].website.phone"
			:email="defaults[locale].website.email"
			:navigation="langmenu"
		/>
		<nav class="main-nav delay fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link
							class="logo"
							:to="{ path: localePath('/', locale), hash: route.name.startsWith('index') && '#top' }"
						/>
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">
									<font-awesome-icon class="icon" icon="fal fa-times" size="1x" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" class="nav-item">
									<span @click="hideMobileNav">
										<nuxt-link :to="item.filename">
											<span>{{ item.header }}</span>
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ $t(item.language) }}</span>
										</nuxt-link>
									</div>
								</li>
							</ul>
						</div>
						<div class="buttons">
							<div class="menu-button button mobile-only" @click="toggleMobileNav">
								<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
								<span>{{ $t('buttonMenu') }}</span>
							</div>
							<a
								class="button phone mobile-only"
								:href="`tel:${$phoneRaw(defaults[locale].website.phone)}`"
								aria-label="Call us"
							>
								<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
								<span>{{ $t('buttonCall') }}</span>
							</a>
							<book-button class="button book-now cta reserve-now">
								<font-awesome-icon class="icon" icon="fal fa-calendar-alt" size="1x" />
								<span>{{ $t('buttonMakeReservation') }}</span>
							</book-button>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();
await fetchDefaults();

const showMobileNav = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};
</script>

<style lang="scss" scoped>
@media (min-width: 1201px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.main-nav {
	position: absolute;
	top: 42px;
	left: 0;
	right: 0;
	z-index: 1000;
	transition: background 0.3s ease-in-out;

	a {
		color: #fff;
	}

	.nav-content ul {
		display: inline-flex;
	}

	&.fixed {
		position: fixed;
		top: 0;

		a:not(.button) {
			color: $body-color;

			&:hover,
			&.active {
				color: $cta-color;
			}
		}
	}

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 6px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 15px 25px;
			margin: 0;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}

		.icon {
			display: none;
			margin-right: 5px;
			font-size: 13px;
		}
	}
}

.menu-button {
	cursor: pointer;
	background: none;
}

.logo {
	width: 220px;
	height: 102px;
	background: url('~/assets/images/logo-quinta-das-maravilhas-full-white.png') no-repeat center center;
	background-size: 220px;
}

.no-heading-image {
	.main-nav {
		a:not(.button) {
			color: $body-color;
		}
	}

	.logo {
		width: 147px;
		height: 68px;
		background: url('~/assets/images/logo-quinta-das-maravilhas-full-black.png') no-repeat center center;
		background-size: 147px;
	}
}

.fixed {
	.logo {
		width: 147px;
		height: 68px;
		background: url('~/assets/images/logo-quinta-das-maravilhas-full-black.png') no-repeat center center;
		background-size: 147px;
	}

	.nav-bar {
		padding: 15px 0;
	}
}

.nav-content {
	margin: auto;

	a {
		text-decoration: none;
		text-transform: uppercase;
		margin: 0 10px;
		letter-spacing: 1px;
		font-weight: 700;

		&.active,
		&:hover {
			color: $cta-color;
		}
	}

	.menu-close {
		color: #fff;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		border-top: 1px solid #c5946f;
		display: inline-block;

		.language {
			margin: 20px 0;

			.flag::before {
				content: '';
				width: 16px;
				position: relative;
				height: 16px;
				margin-right: 6px;
				display: inline-block;
			}

			.flag.en::before {
				background: url('~/assets/images/en.png') no-repeat;
			}

			.flag.nl::before {
				background: url('~/assets/images/nl.png') no-repeat;
			}

			.flag.pt::before {
				background: url('~/assets/images/pt.png') no-repeat;
			}

			.flag.de::before {
				background: url('~/assets/images/de.png') no-repeat;
			}

			.flag.fr::before {
				background: url('~/assets/images/fr.png') no-repeat;
			}

			.flag.es::before {
				background: url('~/assets/images/es.png') no-repeat;
			}
		}

		a {
			font-size: 15px;
			color: #fff;

			&.router-link-exact-active,
			&:hover {
				color: $cta-color;
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

@media (max-width: 1280px) {
	.nav-bar a {
		font-size: 14px;
	}
}

@media (max-width: 1200px) {
	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
		}
	}

	.nav-bar {
		padding: 15px 0;

		.buttons {
			.menu-button {
				color: $cta-color;
				padding: 0 10px;

				svg {
					font-size: 26px;
					margin: 0 15px 0 0;
				}
			}

			.icon {
				display: unset;
			}
		}
	}

	.main-nav {
		position: fixed !important;
		background: rgba(254 253 252 / 86%);
		backdrop-filter: blur(17px);
		top: 0;
		box-shadow: 0 0 10px rgb(0 0 0 / 5%);

		.logo {
			width: 147px;
			height: 68px;
			background: url('~/assets/images/logo-quinta-das-maravilhas-full-black.png') no-repeat center center;
			background-size: 147px;
		}

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;
				padding: 10px 20px;

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 7px 0;
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: rgba(254 253 252 / 86%);
			backdrop-filter: blur(17px);
			text-align: left;
			padding: 20px 0;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
			height: 100vh;

			a {
				color: #333;
				text-transform: none;
				font-weight: 400;
				font-size: 26px;
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center;

				&::before {
					content: '';
					display: block;
					width: 0;
					height: 2px;
					background: #c5946f;
					transition: all 0.3s ease-in-out;
				}

				&:hover::before {
					width: 20px;
				}
			}
		}
	}

	.nav-content {
		.lang-nav a {
			color: #000;
			text-transform: none;
			font-size: 13px;
			font-weight: 400;

			&.active {
				font-weight: 700;
			}
		}

		.menu-close {
			display: block;
			color: #333;
			font-size: 40px;
		}
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons {
		gap: 3px;

		.button {
			padding: 12px;
			margin: 0 0 0 5px;

			&.menu-button {
				padding: 8px;

				.icon {
					font-size: 28px;
				}
			}

			.icon {
				margin-right: 0;
				font-size: 20px;
			}

			span {
				display: none;
			}
		}
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons span {
		display: none;
	}

	.nav-bar .logo {
		font-size: 22px;
	}
}
</style>
